import React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { useGoal } from 'gatsby-plugin-fathom';

import PageTemplate from '../../templates/landing';
import MetaTags from '../../components/MetaTags';
import Testimonial from '../../components/Testimonial';
import siteConfig from '../../../data/SiteConfig';

import { printGbpWithUsd } from '../../utils/formatting';

const faqs = [
  {
    question:
      'We already have an AWS account and have started some early-stage prototyping in a development environment. Can we still avail of the Launchpad service?',
    answer:
      'Yes. In your application form, tell me a bit about the state of your existing AWS environment and codebase. We can then set up a call to see how everything will fit together.',
  },
  {
    question: 'Who is this service NOT for?',
    answerHtml: () => (
      <div>
        If any of the following apply, you may not be a good fit for this
        service:
        <ul>
          <li>
            You’re part of a large organisation who already has their own cloud
            platform/DevOps engineers/team dedicated to provisioning new cloud
            application environments
          </li>
          <li>
            You’re undecided whether to adopt AWS serverless and/or have no
            knowledge within your team about the core AWS serverless services
            such as Lambda (a{' '}
            <a href="https://serverlessfirst.com/services/call/">
              Clarity Call
            </a>{' '}
            or{' '}
            <a href="https://serverlessfirst.com/services/roadmap/">
              Application Roadmap
            </a>{' '}
            might work better for you here)
          </li>
          <li>
            You’re building a non-production/throwaway prototype project where a
            multi-account AWS environment would be overkill
          </li>
          <li>Your serverless app is already in production</li>
        </ul>
      </div>
    ),
  },
  {
    question: 'What happens after I apply?',
    answer:
      'I will review your application and reply by email, typically within one business day, with any immediate follow-up questions, and to schedule a preliminary conversation to ensure the service is the right fit for you.',
  },
  {
    question: 'What happens after I pay?',
    answer:
      'Once payment is received, I will send you through a checklist of items I need from you. Once I have access to your AWS management account and GitHub repo, I will begin work on setting everything up. I will also schedule a date with you for the handover session at this time.',
  },
  {
    question:
      'Can we use CDK or Serverless Stack instead of Serverless Framework?',
    answer:
      'Currently the application stacks that I use (which contain the API Gateway/AppSync, Lambda, DynamoDB and Cognito resources) are optimised for Serverless Framework. If you prefer using CDK or Serverless Stack, I can adapt the repo and workflow scripts to use one of these frameworks instead. There will be an extra fee for this.',
  },
  {
    question:
      'I still have more questions that I’d like to ask before applying.',
    answer: 'I’d love to help. Email me at paul@serverlessfirst.com.',
  },
];

const Page = ({ data, path }) => {
  const pvGoal = useGoal(siteConfig.fathomConfig.goals.LAUNCHPAD_PV);
  const startApplicationGoal = useGoal(
    siteConfig.fathomConfig.goals.LAUNCHPAD_APPLY_START
  );
  pvGoal(0);

  const ApplyCTA = (props = { showPromise: false }) => (
    <div className="text-center mt-10 lg:mt-2 mb-10 mx-auto">
      <a
        href={siteConfig.launchpadService.applicationFormUrl}
        className="inline-block font-sans uppercase  btn px-4 py-2 bg-primary-600 hover:bg-primary-500 text-white hover:text-white rounded-md mb-2"
        target="_blank"
        rel="noreferrer"
        onClick={() => startApplicationGoal(0)}
      >
        Apply now »
      </a>
      {props.showPromise && (
        <p className="text-base text-gray-700 italic mt-4">
          Don’t worry, there’s no risk or obligation and it’s free to apply.
        </p>
      )}
    </div>
  );

  return (
    <PageTemplate maxWidthClassName="max-w-4xl">
      <MetaTags
        title="Serverless Launchpad"
        description="Hire a serverless DevOps expert to bootstrap your AWS environment and Continuous Delivery pipeline for your new project"
        path={path}
        twitterCardType="summary_large_image"
        image={data.launchpadBannerImage.childImageSharp.fluid.src}
      />
      <div className="px-4 md:px-8">
        <div className="max-w-prose mx-auto">
          <section className="pt-16 pb-8 rocket-background">
            <h1 className="page-heading uppercase mb-2 text-4xl">
              Serverless Launchpad
            </h1>
            <h3 className="text-center mt-0 text-gray-600 font-medium">
              A done-for-you DevOps service & starter kit{' '}
              <br className="hidden md:inline-block" />
              for startups building on AWS
            </h3>
            <p className="italic text-center leading-6">
              Start shipping quality code to production immediately without
              burning weeks on wrangling AWS environments and configuring dev
              tooling.
            </p>
            <ApplyCTA showPromise />
          </section>
          {/* ======== PAIN ====== */}
          <section>
            <h2 className="text-center tracking-tight text-3xl">
              Need to set up cloud environments &amp; delivery pipelines for
              your new project but not sure where to start?
            </h2>
            <p>
              Developing and deploying any workload on AWS—serverless or
              otherwise—can be error prone, time consuming and risk laden. You
              need to think about:
            </p>
            <ul>
              <li>
                How can each developer have their own{' '}
                <span className="font-extrabold">
                  individual cloud development environment
                </span>{' '}
                and access real cloud services without interfering with others?
              </li>
              <li>
                How do you{' '}
                <span className="font-extrabold">automate deployments</span> to
                staging and production environments, ensuring they are fast and
                repeatable?
              </li>
              <li>
                How do you design a{' '}
                <span className="font-extrabold">
                  future-proof codebase structure
                </span>
                , capable of expanding with fast-growing feature set?
              </li>
              <li>
                What <span className="font-extrabold">tests</span> do you need
                to run to ensure the quality of your codebase and to maximise
                speed of feedback?
              </li>
              <li>
                Are you following{' '}
                <span className="font-extrabold">
                  AWS’s recommended best security practices
                </span>
                ?
              </li>
              <li>
                Where do you find the{' '}
                <span className="font-extrabold">documentation</span> to help
                you make all of these decisions?
              </li>
            </ul>
            <p>
              You chose serverless to avoid undifferentiated heavy-lifting, but
              now you’ve all these problems to solve before you can start
              building.
            </p>

            {/* ======== DREAM ====== */}
            <h2 className="text-center tracking-tight text-3xl">
              Ship new features to production on day 1
            </h2>
            <p>Imagine a future for your new product where:</p>
            <ul>
              <li>
                New developers get productive quickly through easy-to-understand
                documentation.
              </li>
              <li>
                Quality checks run at every point in the developer workflow to
                identify issues as early as possible.
              </li>
              <li>
                You can confidently and continuously deploy changes right
                through to production multiple times per day.
              </li>
              <li>
                Developers can add new services into the architecture with their
                own pipeline.
              </li>
              <li>
                Your team grows to become self-sufficient in the AWS cloud
                environment while having an expert on-hand for advice on issues
                they cannot resolve themselves.
              </li>
            </ul>

            {/* ======== FIX ====== */}
            <h2 className="text-center tracking-tight text-3xl">
              The Serverless Launchpad service gives you all this and more
            </h2>
            <p>
              By hiring me to build the launchpad for your new serverless
              project, you free up your team to focus on building features for
              your new product and directly delivering value to your users.
            </p>
            <h3 className="text-2xl text-gray-800">Here’s what you get</h3>
            <div className="pb-6">
              <a
                href={data.launchpadOverviewImage.childImageSharp.fluid.src}
                target="_blank"
                rel="noreferrer"
              >
                <Img
                  fluid={data.launchpadOverviewImage.childImageSharp.fluid}
                  alt="Serverless Launchpad service components"
                />
              </a>
            </div>
            <p>The solution is split into four areas:</p>
            <ol>
              <li>
                <span className="font-bold">AWS Landing Zone</span> — A
                multi-account AWS account setup using AWS Organizations
                (configured as IaC with OrgFormation). Each account comes with
                CloudTrail auditing, billing alerts and least-privilege IAM
                roles for use with continuous deployment and testing all
                pre-installed. SSO is enabled to allow team members to easily
                connect to each account.
              </li>
              <li>
                <span className="font-bold">Architecture Scaffold</span> — An
                extensible code repo containing a fully functional REST API
                service using API Gateway, Lambda, Cognito and DynamoDB. The
                scaffold also supports addition of other services and easily
                allows for code sharing between them. Serverless Framework is
                used to to manage and deploy resource configuration.
              </li>
              <li>
                <span className="font-bold">Dev Environments</span> — Each
                developer can deploy their own instance of the product as
                isolated stacks to the dev account. Multiple test types and
                quality tools come built in. The dev environment is optimised
                for Node.js and is TypeScript enabled.
              </li>
              <li>
                <span className="font-bold">Delivery Automation</span> — CI/CD
                pipelines using GitHub Actions which run checks on Pull Requests
                and deploy services to target cloud environments on merge to
                main branch.
              </li>
            </ol>
            <p>
              Unlike other cloud landing zone setup services, everything that I
              set up for you—including all the AWS Organization-level and CI/CD
              pipeline configuration—uses Infrastructure-as-Code. These IaC
              templates (and the scripts which invoke them) will all be included
              in your GitHub repo. This makes it much easier for your team to
              see what customisations have been made to your AWS environment and
              for you to apply further changes to these.
            </p>
            <p>
              In addition to the above items which are delivered as part of the
              Launchpad service, I also offer multiple{' '}
              <a href="/services/guidance">guidance options</a> for an extended
              period post-delivery where I can field questions and help your
              developers with any issues they may have.
            </p>
            <h3 className="text-2xl text-gray-800">How it works</h3>
            <ol>
              <li>
                After you apply, we’ll schedule a call where I’ll ask you a few
                questions about your team and your project.
              </li>
              <li>
                I’ll send you through a checklist of things I need from you
                before I can get started. This will typically involve you
                creating a new GitHub repository and a new AWS account (which
                will act as the management account for your organization) and
                granting me access to both (via an IAM user for the AWS
                account).
              </li>
              <li>
                I’ll then get to work on configuring the AWS environments,
                pushing and deploying the code, setting up pipelines, running
                several checks and producing documentation.
              </li>
              <li>
                I’ll notify you once everything’s ready, and your team can then
                start immediately adding features right through to production by
                following the instructions in the provided documentation.
              </li>
              <li>
                We’ll schedule a handover call a few days afterwards once your
                team has had a chance to familiarise yourself with the codebase
                and docs and to test out the pipeline. We can also discuss any
                ongoing support you may need at this time.
              </li>
            </ol>
            <p>
              The price of the service is{' '}
              <span className="font-bold">
                {printGbpWithUsd(siteConfig.launchpadService.priceGBP)}
              </span>
              .
            </p>
          </section>
          <ApplyCTA />

          <section>
            <h2 className="text-center tracking-tight text-3xl">
              Learn from a seasoned serverless architect
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-12">
              <div className="sm:col-span-2">
                <p>
                  I’m Paul Swail, an independent cloud architect who helps
                  development teams make the transition to serverless. Over the
                  past 4 years, I’ve helped clients build and launch scores of
                  greenfield serverless projects on AWS.
                </p>
                <p>
                  During this time, I’ve accumulated a ton of useful practices,
                  templates, code snippets, plugins, architectural patterns,
                  sensible defaults and optimised workflows. I’ve used these to
                  help development teams with zero or limited cloud expertise
                  move fast with quality checks built in right through the
                  development and release cycle. I’ve found out the hard way
                  what works well and what doesn’t.
                </p>
                <p>
                  I’ve now integrated these learnings into this streamlined
                  Serverless Launchpad service.
                </p>
              </div>
              <div className="order-first sm:order-last mt-4 mb-8 md:mt-0">
                <Img
                  fluid={data.paulImage.childImageSharp.fluid}
                  alt="Paul Swail"
                  className="sm:rounded-2xl"
                />
              </div>
            </div>
          </section>
          <section className="mb-8">
            <h2 className="text-center tracking-tight text-3xl">
              What clients are saying
            </h2>
            <Testimonial
              authorName="Darren Gibney"
              authorTitle="Partner &amp; CTO at 3Advance"
              authorPhotoUrl="/img/client-logos/darren-gibney.jpg"
            >
              <div>
                <p>
                  Paul’s focus on developer experience was probably the thing I
                  most liked about his work with us. He raised the bar for our
                  team, in many ways{' '}
                  <strong>
                    we’ve surpassed our previous stack in terms of IaC, CI/CD
                    and testing
                  </strong>
                  .
                </p>
                <p>
                  Paul knows his stuff and instantly had respect of all team
                  members and leadership.{' '}
                  <strong>
                    Our team literally went from nothing on AWS and Serverless
                    to feeling like there is not much we can’t do.
                  </strong>
                </p>
              </div>
            </Testimonial>
          </section>
          <section>
            <h2 className="text-center tracking-tight text-3xl">
              Still have questions?
            </h2>
            {faqs.map((faq) => (
              <div key={faq.question}>
                <p className="font-bold italic mb-2">{faq.question}</p>
                {faq.answerHtml && <faq.answerHtml />}
                {!faq.answerHtml && <p>{faq.answer}</p>}
              </div>
            ))}
          </section>
          <section className="px-4 md:px-8">
            <ApplyCTA showPromise />
          </section>
          <section>
            <h2 className="text-center tracking-tight text-3xl">
              Availability is limited
            </h2>
            <p>
              I can only take on 1–2 Serverless Launchpad engagements per month
              and I may need a few weeks’ lead time before getting started. So
              if you’re hoping to begin development soon, apply now to reserve
              your spot.
            </p>
          </section>
        </div>
      </div>
    </PageTemplate>
  );
};

export const query = graphql`
  query {
    launchpadOverviewImage: file(
      relativePath: { eq: "slslaunchpad-service-overview.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    launchpadBannerImage: file(
      relativePath: { eq: "sls-launchpad-banner.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    paulImage: file(relativePath: { eq: "paulswail_2021-09_a.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Page;
